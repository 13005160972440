import {getHeaders} from '../../utls/headerGenerator';
export async function getAllLessonsService(){

    const options = { 
        method: 'GET', 
        headers: new Headers(await getHeaders()), 
        mode: 'cors',
        credentials: 'omit'
      };
    
    const endpoint = process.env.REACT_APP_BFF + '/getAllLessons';

    let detailsResponse =
      await fetch(endpoint,options)
        .then(response => {
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });

// debugger;
    return detailsResponse;

    // return true;
}