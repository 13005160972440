import React, { useState, useEffect, useRef } from "react";
import Spinner from 'react-bootstrap/Spinner'
import bcrypt from '../../../../node_modules/bcryptjs';
import { Accordion } from "react-bootstrap";
import { encryptPaymentDetails, encryptTransactionDetails } from '../../../services/jwtPaymentTransformer';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import { submitBookstorePO } from '../../../services/bffServices/submitOrder';
import { addTax } from '../../../services/bffServices/updateShoppingCart';




const saltRounds = 10;



export default function PaymentComponent(props) {
    const [cc, setcc] = useState("");
    const [expiry, setExpiry] = useState("");
    const [cvv, setcvv] = useState("");
    const [paymentError, setPaymentError] = useState("");
    const [submitButton, setSubmitButton] = useState("Submit");
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [show, setShow] = useState(false);
    const [submitButtonLabel, setsubmitButtonLabel] = useState("Submit");
    const [creditCardRequired, setCreditCardRequired] = useState();
    const [email, setemail] = useState();
    const [userName, setUserName] = useState({});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");
    const [postalCode, setPostalCode] = useState("");
    // const [submitButton, setSubmitButton] = useState("");
    const [subTotal, setSubTotal] = useState(props.totalAmount);
    // const [postalCode, setPostalCode] = useState("");
const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function spinnerEnabled() {
        return (
            <div>
                <Spinner animation="border" size="sm" />
            </div>
        );
    }

    useEffect(async () => {
        try {

            await setSubTotal(props.totalAmount);

        } catch (err) {
            //console.log(err);
        }

    }, [subTotal])
    async function submitProductOrder() {

        var payload = {};
        setPaymentError("");
        setDisableSubmitButton(true)

        setsubmitButtonLabel(() => {
            return (
                <>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </>
            )
        })
        var allFieldsCompleted = false;

        if (cc != "" || expiry != "" || cvv != "" || firstName != "" || lastName != "" || email != "" || country != "" || province != "" || postalCode != "") {
            allFieldsCompleted = true;
        } else {
            allFieldsCompleted = false;
        }


        // if (cc != "" || expiry != "" || cvv != "") {
        if (allFieldsCompleted) {
            // var taxes = await addTax();

            const taxPayload = {
                "province": province,
                // "scId": cookies.get('sb-sc'),
                "scId": sessionStorage.getItem('sb-sc'),
                "tax": {
                    "hst": 0
                }
            }

            await addTax(taxPayload);

            // await addTax(payload);

            payload = {
                // "shoppingCartId": cookies.get('sb-sc') || "1234",
                "shoppingCartId": sessionStorage.getItem('sb-sc'),
                "fName": firstName || "",
                "lName": lastName || "",
                "userName": email,
                "email": email,
                "addressInfo": {
                    "country": country,
                    "province": province,
                    "postalCode": postalCode
                },
                "ccDetails": {
                    "cardNum": cc,
                    "cardExpiry": expiry,
                    "cvv": cvv
                },
                "transactionDetails": {
                    // "transactionNum": cookies.get('sb-sc'),
                    "transactionNum": sessionStorage.getItem('sb-sc'),
                    "tax": parseFloat(props.amount?.tax) || 0,
                    "totalAmount": parseFloat(props.amount?.totalAmount) || parseFloat(subTotal),
                    "currency": props.currency
                }
            }
            await encryptPaymentDetails(payload.ccDetails);
            await encryptTransactionDetails(payload.transactionDetails);

            setDisableSubmitButton(true)
            // setSubmitButton(spinnerEnabled);

            // await updateSCCustomerInfo(payload);

            const results = await submitBookstorePO(payload);///put in the submit bookstore order
            //console.log("RESULTSSS --> " + JSON.stringify(results));

            // if (results.errorCode != 62) {
            //     handleClose();
            //     sessionStorage.removeItem('sb-sc');
            //     sessionStorage.setItem('sc-orderId', results.id);

            //     window.location.href = "/thankyou";
            // }


            // if (results.errorCode == 62 || results.errorCode != null || results.errorCode != undefined) {
            //     setPaymentError(() => {
            //         return (
            //             <>
            //                 <Alert key="danger" variant="danger">
            //                     {/* {results.errorMsg} */}
            //                     Transaction Failed, please validate address information and credit card information and try again.  If issue persistes, contact <a href="mailTo:support@smart-biology.com">support@smart-biology.com</a>
            //                 </Alert>
            //             </>
            //         )
            //     });
            //     setsubmitButtonLabel("Submit");
            //     setDisableSubmitButton(false);
            // }
            
            if (results.results != "SUCCESS") {
                if (results.errorCode != null && results.errorCode != undefined) {
                    // setExistingUserIn(true);
                    setDisableSubmitButton(false);
                    if (results.errorCode == 62) {
                        setPaymentError(() => {
                            return (
                                <>
                                    <Alert key="danger" variant="danger">
                                        Your transaction was unsuccessful. Please double-check your address and credit card details, then try again. If the issue persists, contact <a href="mailTo:support@smart-biology.com">support@smart-biology.com</a><br />
                                        <br />(Error: {results.errorMsg && results.errorMsg.message ? results.errorMsg.message : 'Unknown error message'}) 
                                    </Alert>
                                </>
                            )
                        });
                    } else {
                        setPaymentError(() => {
                            return (
                                <>
                                    <Alert key="danger" variant="danger">
                                        Your transaction was unsuccessful, please confirm the method of payment and retry. If the issue persists, contact <a href="mailTo:support@smart-biology.com">support@smart-biology.com</a><br />
                                        <br />(Error: {results.errorMsg && results.errorMsg.message ? results.errorMsg.message : 'Unknown error message'})
                                    </Alert>
                                </>
                            )
                        });
                    }

                    if (results.results == "SUCCESS") {
                        handleClose();
        
                        await sessionStorage.removeItem('sb-sc');
                        await sessionStorage.setItem('sc-orderId', results.id);
        
                        window.location.href = "/thankyou";
                    }
            
                } else {
                    setPaymentError(() => {
                        return (
                            <>
                                <Alert key="danger" variant="danger">
                                    Your transaction was unsuccessful. If the issue persists, contact <a href="mailTo:support@smart-biology.com">support@smart-biology.com</a><br />
                                    <br />(Error: Unknown error occurred)
                                </Alert>
                            </>
                        );
                    });
                }
                setsubmitButtonLabel("Submit");
                setDisableSubmitButton(false);
            }


        } else {


            setPaymentError(() => {

                return (
                    <>
                        <Alert key="danger" variant="danger">
                            Please enter all Credit Card Details are added.
                        </Alert>
                    </>
                )
            });


            setDisableSubmitButton(false);
            setsubmitButtonLabel("Submit")

        }

    }

    return (
        <>
            <Button onClick={handleShow}>
                Pay Invoice
            </Button>

            <Modal
                backdrop="static"
                keyboard={false}
                show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Pay Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body><br />
                    <h6>Billing Information</h6>
                    <table width="100%">
                        <tr>
                            <td width="30%">
                                <label>Email Address</label>
                            </td>
                            <td>
                                <input class="form-control form-control-sm"  onChange={event => setemail(event.target.value)} type="text" autoComplete="text" name="email" id="email" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>First Name</label>
                            </td>
                            <td>
                                <input class="form-control form-control-sm" type="text" autoComplete="text"  onChange={event => setFirstName(event.target.value)} name="fname" id="fname" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Last Name</label>
                            </td>
                            <td>
                                <input class="form-control form-control-sm" type="text" autoComplete="text" name="lname"  onChange={event => setLastName(event.target.value)} id="lname" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Country</label>
                            </td>
                            <td>
                                <input class="form-control form-control-sm" type="text" autoComplete="text" name="lname"  onChange={event => setCountry(event.target.value)} id="lname" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Province/State</label>
                            </td>
                            <td>
                                <input class="form-control form-control-sm" type="text" autoComplete="text" name="lname" onChange={event => setProvince(event.target.value)} id="lname" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Postal / Zip Code</label>
                            </td>
                            <td>
                                <input class="form-control form-control-sm" type="text" autoComplete="text" name="lname" onChange={event => setPostalCode(event.target.value)} id="lname" />
                            </td>
                        </tr>
                    </table>
                    <br />
                    <hr style={{ 'margin': "auto" }} width="75%" />
                    <br />
                    <h6>Payment Information</h6>
                    <table width="100%">
                        <>
                            <tr>
                                <td>
                                    <label>Credit Card Number</label>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" onChange={event => setcc(event.target.value)} type="text" autoComplete="text" name="cc" id="cc" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Expiry</label>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" onChange={event => setExpiry(event.target.value)} type="text" autoComplete="text" name="expiry" id="expiry" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>CVV</label>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" onChange={event => setcvv(event.target.value)} type="text" autoComplete="text" name="cvv" id="cvv" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>eMail</label>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" autoComplete="text" name="email" id="email" value={email} disabled />
                                </td>
                            </tr>
                        </>


                    </table><br />
                    {paymentError}

                </Modal.Body>

                <Modal.Footer>
                    <table width="100%">
                        <tr>
                            <td align="left">
                                <div class="AuthorizeNetSeal" align="left"> <script type="text/javascript" language="javascript">var ANS_customer_id="2705aece-1ac4-41ed-963d-0bfd118659d8";</script>
                                    <a href="https://verify.authorize.net/anetseal/?pid=2705aece-1ac4-41ed-963d-0bfd118659d8&amp;rurl=https://www.smart-biology.com" onmouseover="window.status='http://www.authorize.net/'; return true;" onmouseout="window.status=''; return true;" onclick="window.open('https://verify.authorize.net/anetseal/?pid=2705aece-1ac4-41ed-963d-0bfd118659d8&amp;rurl=https://www.smart-biology.com','AuthorizeNetVerification','width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes'); return false;" rel="noopener noreferrer" target="_blank">
                                        <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" width="90" height="72" border="0" alt="Authorize.Net Merchant - Click to Verify" />
                                    </a>
                                    <a href="http://www.authorize.net/" style={{ 'font-size': "10px", "text-decoration": "none", color: "black" }} id="AuthorizeNetText" target="_blank"><div><small>Credit Card <br />Processing</small></div></a>
                                </div>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                    <th colspan="2">Payment Details</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Subtotal:
                                        </td>
                                        <td>
                                            ${subTotal}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Tax:
                                        </td>
                                        <td>
                                            0
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Total:
                                        </td>
                                        <td>
                                            ${subTotal}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td align="right">
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>{'  '}
                                <Button onClick={() => submitProductOrder()} disabled={disableSubmitButton}>
                                    {submitButtonLabel}
                                </Button>
                            </td>
                        </tr>
                    </table>

                </Modal.Footer>
            </Modal>

        </>
    )
}