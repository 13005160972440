import Container from "react-bootstrap/Container";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Image from "react-bootstrap/Image";
import Gallery from "react-grid-gallery";
const url = require("url");

// const domainURL = "https://www.smart-biology.com" //https://www.smart-biology.com/wp-content/uploads/2020/11/Life-U4-C14-EFE-M1-L1-Figure-1.jpg
const domainURL = "https://storage.googleapis.com/sb-general"; //https://storage.googleapis.com/sb-general/

export default function LessonFiguresComponent(props) {
	const [figures, setFigures] = useState("blah");
	const [lastFocus, setLastFocus] = useState();
	const [figureContent, setFigureContent] = useState("blah");
	const [isGalleryOpen, setGalleryOpen] = useState(false);

	const handleOpenGallery = async () => {
		setLastFocus(document.activeElement);
		await setGalleryOpen(true);
		document.getElementById("closeButton").focus();
	};

	const handleCloseGallery = () => {
		
		setGalleryOpen(false);
		lastFocus.focus();
	};

	const handleCloseClick = () => {
		
		handleCloseGallery();
	};

	var parsedURL = url.parse(props.figures.figureImg_en);
	var updatedURL = domainURL + parsedURL.pathname;

	const IMAGES = [
		{
			src: updatedURL,
			thumbnail: updatedURL,
			thumbnailWidth: 600,
			thumbnailHeight: 250,
			caption: props.figures.content_en,
		},
	];

	const [modalShow, setModalShow] = useState(false);

	const dialogRef = useRef(null);
    const firstFocusableRef = useRef(null);
    const lastFocusableRef = useRef(null);
	useEffect(() => {
        const dialog = dialogRef.current;
        const firstFocusableElement = firstFocusableRef.current;
        const lastFocusableElement = lastFocusableRef.current;

        const handleKeyDown = (e) => {
            // Handle Shift+Tab when on the first focusable element
            if (e.target === firstFocusableElement && e.key === 'Tab' && e.shiftKey) {
                e.preventDefault();
                lastFocusableElement.focus();
            }
            // Handle Tab when on the last focusable element
            else if (e.target === lastFocusableElement && e.key === 'Tab' && !e.shiftKey) {
                e.preventDefault();
                firstFocusableElement.focus();
            }
        };

        // Attach event listener to dialog
        if (dialog) {
            dialog.addEventListener('keydown', handleKeyDown);
        }

        // Clean up event listener on unmount
        return () => {
            if (dialog) {
                dialog.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []); // Empty dependency array ensures this runs only once when component mounts


	return (
		<>
			<Card>
				<Container>
					<Row style={{ "text-align": "center!important" }}>
						<Col>
							<div
								style={{
									"margin-left": "auto",
									width: "100%",
									"padding-top": "10px",
								}}
							>
								<div
									className="modal show"
									style={{ display: "block", position: "initial" }}
								>
									<Modal
										role="dialog"
										aria-labelledby="figureHeader"
										aria-modal="true"
										aria-label="Lesson Figure Model"
										show={isGalleryOpen}
										size="xl"
										onHide={() => handleCloseClick()}
										centered
										dialogClassName="custom-modal"
										style={{ background: "#14141465" }}
										backdrop="static"
										tabindex={isGalleryOpen ? 0 : -1} 
									>
										<Modal.Header
											style={{ display: "flex"}}
										>
											
										<div width='100%'style={{textAlign: 'left!important'}}><h1 style={{textAlign: 'left'}} id="figureHeader">Lesson Figure</h1></div>
											
												<button
													id="closeButton"
													className="primary-button"
													onClick={handleCloseClick}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														height="20"
														width="20"
														viewBox="0 0 384 512"
														role="img"
														aria-labelledby="closeButtonText"
													>
														<title id="closeButtonText">Close (esc)</title>
														<path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
													</svg>
												</button>
											
										</Modal.Header>
										<Modal.Body>
											{IMAGES.map((image) => (
												<img
													key={image.src}
													src={image.thumbnail}
													alt={image.caption}
													onClick={() => handleOpenGallery()}
													width="100%"
													height="100%"
												/>
											))}
										</Modal.Body>
										<Modal.Footer>
											<div
												style={{ color: "black" }}
												dangerouslySetInnerHTML={{
													__html: props.figures.content_en,
												}}
											/>
										</Modal.Footer>
									</Modal>
								</div>

								{/* <button
								 style={{borderColor: 'transparent', backgroundColor: 'transparent'}}
								onClick={() => {
									
									 handleOpenGallery();
								}}> */}

								<div style={{ display: "flex", flexWrap: "wrap", margin: "5px"}}>
									{IMAGES.map((image) => (
										<img
											key={image.src}
											src={image.thumbnail}
											alt={image.caption}
											width="100%"
										/>
									))}
								</div>
								{/* </button> */}
							</div>

							{/* <div
								style={{ "text-align": "right", "padding-bottom": "5px" }}
								className="text-muted"
							>
								Click Image to Enlarge
							</div> */}
						</Col>
					</Row>
					<Col></Col>
				</Container>
				<Card.Footer >
					{" "}
					<div >
						<div
							dangerouslySetInnerHTML={{ __html: props.figures.content_en }}
						/>
					</div>
				</Card.Footer>
			</Card>
		</>
	);
}
