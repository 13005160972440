import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import postCommentResponse from "../discussion-forum/services/discussionForumEntry";
import React, { useState, useEffect, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import GetAllCommentsandReplies from "../discussion-forum/services/discussionForumListEntries";

function DiscussionFormMainComponenet(props) {
	const queryParams = new URLSearchParams(window.location.search);
	const [comment, setComment] = useState();
	const [unitId, setUnitId] = useState(sessionStorage.getItem("activeUnit"));
	const [moduleId, setModuleId] = useState(queryParams.get("id"));
	const [submittionMsg, setsubmittionMsg] = useState("");
	const [commentList, setCommentList] = useState(
		"No questions have been asked"
	);

	//unitId={sessionStorage.getItem("activeUnit")} moduleId={queryParams.get('id')}

	const submitComment = async () => {
		setCommentList("");

		if (comment != null || comment != undefined) {
			const payload = {
				unit: unitId,
				chapter: "c-1234",
				module: moduleId,
				isParentComment: "Y",
				parentComment: "N/A",
				replyTo: "N/A",
				comment: comment,
			};

			const results = await postCommentResponse(payload);

			setComment("");
			setCommentList(() => {
				return <GetAllCommentsandReplies />;
			});
		} else {
			setsubmittionMsg(() => {
        document.getElementById('field').focus();
				return (
					<>
						<Alert id="errorMessage" key="danger" variant="danger" style={{ color: 'black' }}>
							<b>Please Enter a comment or question.</b>
						</Alert>
					</>
				);
			});
		}
	};

	useEffect(async () => {
		setCommentList(() => {
			return <GetAllCommentsandReplies />;
		});
	}, []);

	return (
		<div>
			<h3>Have a Question?</h3>
			<Card>
				<Card.Body>
					{/* <Card.Subtitle className="mb-2 text-muted">Class: BIO293</Card.Subtitle>
            <Card.Subtitle className="mb-2 text-muted">Term: 123 (TBD)</Card.Subtitle> */}
					{/* <Card.Text> */}
            <label for="field">Question</label>
						<Form.Control
              aria-describedby="errorMessage"
              id="field"
							onChange={(event) => setComment(event.target.value)}
							as="textarea"
							rows="5"
							aria-label="Ask a Question"
							value={comment}
						/>
						<br />
						{submittionMsg} <br />
						<button type="submit" className="primary-button" onClick={() => submitComment()}>
							Submit form
						</button>
					{/* </Card.Text> */}
				</Card.Body>
			</Card>
			<hr />
			{commentList}
			<br />
			<br />
		</div>
	);
}

export default DiscussionFormMainComponenet;
