import React, { useState, useEffect, useContext } from "react";
import FormSelect from 'react-bootstrap/FormSelect'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert'

function ArViewComponent(props) {
    const [selectedDiagrams, setSelectedDiagrams] = useState([]);
    const [configDetails, setConfigDetails] = useState(null);


    const handleDiagramChange = (event) => {
        setSelectedDiagrams(Array.from(event.target.selectedOptions, option => option.value));
    };

    const handleLoad = async () => {

        const item = await props.diagrams.find(entry => entry.id === selectedDiagrams[0]);

        setConfigDetails(item)

    }


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <div style={{ width: '40%', height: "200px", padding: "10px", border: "1px solid #ccc", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
                    <h6>Select an AR Structure</h6>
                    <hr />
                    <FormSelect
                        value={selectedDiagrams}
                        onChange={handleDiagramChange}
                    >
                        {props.diagrams.map(diagram => (
                            <option style={{ fontSize: "14px" }} key={diagram.id} value={diagram.id}>
                                {diagram.name}
                            </option>
                        ))}
                    </FormSelect>

            <div style={{ textAlign: 'right', paddingRight: "20px", paddingBottom: "20px" }}>
                <Button onClick={handleLoad} style={{ marginTop: '20px' }}>
                    Load
                </Button>
            </div>
                </div>

                {configDetails && (
    <div style={{
        width: '40%',
        minHeight: "200px",
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        // backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
    }}>
        <h6 style={{ marginBottom: "10px", fontWeight: "bold", textAlign: "center" }}>Current Config</h6>
        <hr />
        <div style={{ textAlign:"left", padding: "10px", lineHeight: "1.8" }}>
            <p><strong>Name:</strong> {configDetails.name}</p>
            
            <div>
                <p><strong>Lessons:</strong></p>
                <ul style={{
                    marginLeft: "20px",
                    listStyleType: "disc",
                    paddingInlineStart: "20px",
                }}>
                    <li>Lesson 1</li>
                </ul>
            </div>
            
            <div>
                <p><strong>Memberships:</strong></p>
                <ul style={{
                    marginLeft: "20px",
                    listStyleType: "disc",
                    paddingInlineStart: "20px",
                }}>
                    <li>Membership 1</li>
                </ul>
            </div>
        </div>
    </div>
)}

            </div>







        </>
    )
}

export default ArViewComponent;