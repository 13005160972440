
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getSearchResults } from '../../services/search/getSearchResults';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'react-bootstrap/Pagination';




const SearchButton = ({ handleClick }) => {
	const RESULTS_PER_PAGE = 15;

	const [searchTerm, setSearchTerm] = useState("");
	const [hasFocus, setHasFocus] = useState(false);
	const [isFloating, setIsFloating] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [showResults, setShowResults] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [showLoading, setShowLoading] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const totalResults = searchResults.length;
	const totalPages = Math.ceil(totalResults / RESULTS_PER_PAGE);

	const clearResults = () => {
		setSearchResults([]);
		setShowResults(false);
	};

	const handleFocus = () => {
		setHasFocus(true);
		setIsFloating(true);
	};

	const handleBlur = (e) => {
		setHasFocus(false);

		if (e.target.value === '') {
			setIsFloating(false);
		}
	};

	useEffect(() => {
		// Function to remove the attributes if the modal is present
		const removeModalAttributes = () => {
			const modalElement = document.querySelector('.fade.modal.show');
			if (modalElement) {
				modalElement.removeAttribute('role');
				modalElement.removeAttribute('aria-modal');
			}
		};

		// Set up a MutationObserver to watch for changes in the DOM
		const observer = new MutationObserver(() => {
			removeModalAttributes();
		});

		// Start observing the body or a parent container for added nodes (i.e., the modal)
		observer.observe(document.body, { childList: true, subtree: true });

		// Clean up the observer when the component unmounts
		return () => observer.disconnect();
	}, []);


	const handleSubmit = async (event) => {
		event.preventDefault();
		setCurrentPage(1);
		setSearchResults([]);
		setShowLoading(() => {
			return (
				<>
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</>
			)
		})
		var searchResults = await getSearchResults(searchTerm);
		setSearchResults(searchResults);
		setShowLoading("");

		// if(searchResults.length < 0){
		//   setShowLoading("No Results Found");
		// }

		if (searchResults === undefined) {
			searchResults = []
		}

		if (searchResults === undefined || searchResults.length === 0) {
			setShowLoading("No Results Found");
		}
	};

	const handleTextChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleIconClick = () => {
		setShowSearch(true);
	};

	return (
		<>
			<button
				style={{ height: "30px", margin: "auto" }}
				size="sm"
				className="primary-button-search"
				onClick={handleIconClick}

			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					height="16"
					width="16"
					viewBox="0 0 512 512"
					role="img"
					aria-labelledby="tac"
					fill="white"
				>
					<title id="tac">Search Website's Content</title>
					<path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
				</svg>
			</button>
			<Modal
				aria-label="Content Search"
				role="dialog"
				aria-modal="true"
				size="lg"
				show={showSearch}
				onHide={() => setShowSearch(false)}
			>
				{/* <Modal.Header closeButton>
          <Modal.Title>Search Your Textbooks</Modal.Title>
        </Modal.Header> */}

				<Modal.Body>
					<h1 style={{ fontSize: "20px" }} id="searchHeader">
						Search For Content
					</h1>
					<br />
					<Form onSubmit={handleSubmit}>
						<div>
							<h2><label for="search">Search</label></h2>
							<InputGroup name="search">
								<Form.Control

									name="search"
									id="search"
									autoComplete="text"
									type="text"
									value={searchTerm}
									onChange={handleTextChange}
									onKeyPress={(event) => {
										if (event.key === "Enter") {
											handleSubmit(event);
										}
									}}
								/>
								<Button type="submit">Search</Button>
							</InputGroup>
						</div>
					</Form>
					<div
						style={{
							margin: "auto",
							"padding-top": "20px",
							textAlign: "center",
						}}
					>
						{showLoading}
					</div>

					<div
						// role="group"
						// aria-label="search result list"
						style={{ maxHeight: "400px", overflowY: "scroll" }}
					>
						<ul style={{ 'list-style-type': "none", 'padding-left': 0, 'padding-bottom': 0 }}>
							{searchResults.length > 0 ? (
								searchResults
									.slice(
										(currentPage - 1) * RESULTS_PER_PAGE,
										currentPage * RESULTS_PER_PAGE
									)
									.map((result) => (
										<il>
											<Card bg="light" key="light" style={{ margin: "10px" }}>
												<Card.Body>
													<Row
													role="group"
													aria-label="search results"
													>
														<Col sm={9}
														
														
														>
															<h2 style={{ fontSize: "20px" }}>
																{result.unitTitle}
																<div style={{ "font-size": "12px" }}>
																	<br></br>
																	{result.chapterTitle}
																</div>
																<div style={{ "font-size": "12px" }}>
																	{result.moduleTitle}
																</div>
																<div style={{ "font-size": "12px" }}>
																	{result.lessonTitle}
																</div>
															</h2>

															<hr />
															<div style={{ "font-size": "12px" }}>
																<h3 style={{ "font-size": "16px" }}>Preview:</h3>
															</div>
															<span
																style={{ "font-size": "12px" }}
																dangerouslySetInnerHTML={{
																	__html: result.resultsText,
																}}
															/>
														</Col>
														<Col style={{ "text-align": "center", margin: "auto" }}>
															<div
																role="link"
																style={{
																	display: 'inline-block', // To make it behave like a button
																	padding: '10px 15px', // Add some padding
																	border: '1px solid #007bff', // Example border (adjust as needed)
																	borderRadius: '5px',  // Example rounded corners
																	color: '#007bff', // Example color
																	textDecoration: 'none', // Remove underline from the link
																	cursor: 'pointer' // Show a pointer cursor on hover
																}}
																onClick={() => {
																	// Handle the click (e.g., redirect to the URL)
																	window.location.href = `/textbook/module?id=${result.moduleId}&s=${result.lessonId}&c=${result.chapterId}&u=${result.unitId}`;
																}}
															>
																Go to Lesson
															</div>
															{/* <Button
													role="link"
														variant="outline-primary"
														href={
															"https://www.smart-biology.com/textbook/module?id=" +
															result.moduleId +
															"&s=" +
															result.lessonId +
															"&c=" +
															result.chapterId +
															"&u=" +
															result.unitId
														}
													>
														Go to Lesson
													</Button> */}
															{/* <Button variant="outline-primary" href={"http://localhost:3000/textbook/module?id=" + result.moduleId + "&s=" + result.lessonId + "&c=" + result.chapterId + "&u=" + result.unitId}>Go to Lesson</Button> */}
														</Col>
													</Row>
												</Card.Body>
											</Card>
										</il>
									))
							) : (
								<div />
							)}
						</ul>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Pagination>
						<nav aria-label="pagination">
							<Pagination.Prev
								onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
								disabled={currentPage === 1}
							/>
						</nav>
						{Array.from({ length: totalPages }).map((_, i) => (
							<Pagination.Item
								key={i}
								active={i + 1 === currentPage}
								onClick={() => setCurrentPage(i + 1)}
							>
								{i + 1}
							</Pagination.Item>
						))}
						<nav aria-label="pagination">
							<Pagination.Next
								onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
								disabled={currentPage === totalPages}
							/>
						</nav>
					</Pagination>
				</Modal.Footer>
			</Modal>
			<Modal show={showResults} onHide={clearResults}>
				<Modal.Header closeButton>
					<Modal.Title>Search Results</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ul style={{ 'list-style-type': "none", 'padding-left': 0, 'padding-bottom': 0 }}>
						{searchResults.map((result) => (
							<il>
								<div key={result.lessonId}>
									<div>Unit: {result.unitName}</div>
									<small>Chapter: {result.chapterName}</small>
									<br />
									<small>Module: {result.moduleName}</small>
									<br />
									<small>Lesson: {result.lessonName}</small>
									<br />
									<small>Results: {result.textResults}</small>
									<br />
									<small>
										<a
											href={
												"http://localhost:3000/textbook/module?id=62da03a97abfb138425678fe&s=" +
												result.lessonId
											}
										>
											Go to results
										</a>
									</small>
								</div>
							</il>
						))}
					</ul>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={clearResults}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default SearchButton;

