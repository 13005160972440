import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UpdateButtonComponent from '../textbook/updateButtonComponenet';
import LessonFigures from '../textbook/lessonFigures';
import QuizComponent from '../textbook/quizComponent';
import { getLessonByID } from '../../services/bffServices/textbookBFFServices/getLessonDetails';
import QRComponent from '../QR-code-generator/qrComponent'
import React, { useState, useEffect, useRef } from "react";
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton
} from 'video-react';
import ReactPlayer from 'react-player'
import { updateUserProgress } from '../../services/bffServices/textbookBFFServices/updateUserProgress'
import { getUserProgressByItemId } from '../../services/bffServices/getUserDetails';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import CustomAudioPlayer from '../audio-player/audioPlayerComponent';
const url = require('url');
const queryParams = new URLSearchParams(window.location.search);
const moduleId = queryParams.get('id') || "";

const domainURL = "https://storage.googleapis.com/sb-general"

function LessonContentComponent(props) {
    //      const playerRef = useRef(null);

    //   const handleToggleSubtitles = (lang) => {
    //     const video = playerRef.current.getInternalPlayer(); // Get the video element
    //     const tracks = video.textTracks; // Get the subtitle tracks

    //     // Loop through the textTracks and set the correct language
    //     for (let i = 0; i < tracks.length; i++) {
    //       if (tracks[i].language === lang) {
    //         tracks[i].mode = 'showing'; // Show selected language
    //       } else {
    //         tracks[i].mode = 'disabled'; // Hide others
    //       }
    //     }
    //   };
    const [lessonVTT, setLessonVTT] = useState();

    const [lessonTitle, setLessonsTitle] = useState();
    const [lessonBody, setLessonsBody] = useState();
    const [lessonQuiz, setLessonsQuiz] = useState();
    const [lessonVideo, setLessonsVideo] = useState();
    const [lessonVideoPoster, setLessonsVideoPoster] = useState();
    const [lessonFigures, setLessonFigures] = useState([]);
    const [audioFile, setAudioFile] = useState();
    const [interactiveStructures, setInteractiveStructures] = useState();
    const [interactiveStructures2, setInteractiveStructures2] = useState();
    const [interactiveStructures3, setInteractiveStructures3] = useState();
    const [interactiveStructuresDesc_EN, setInteractiveStructuresDesc_EN] = useState();
    const [videoFramePost, setViceoFramePoster] = useState();
    const [lessonAudioLabel, setLessonAudioLabel] = useState("Lesson Audio");
    const [interStrucLabel, setInterStrucLabel] = useState("Interactive Structure / Structure interactive");
    const [figuresLabel, setFiguresLabel] = useState(() => {
        if (sessionStorage.getItem("lang") != "FR") {
            return "Figures"
        }
        if (sessionStorage.getItem("lang") == "FR") {
            return "Les Figures";
        }
    });
    const [lessonTextLabel, setLessonTextLabel] = useState(() => {
        if (sessionStorage.getItem("lang") != "FR") {
            return "Lesson Text"
        }
        if (sessionStorage.getItem("lang") == "FR") {
            return "Texte de la leçon";
        }
    });
    var language = sessionStorage.getItem("lang") || "EN"
    const handURL = '<img loading="lazy" class="size-full wp-image-23264 alignleft" src="' + domainURL + '/wp-content/uploads/2019/01/Interactive-Hand-Symbol.jpg" alt="interact gesture" width="50" height="50" srcset="' + domainURL + '/wp-content/uploads/2019/01/Interactive-Hand-Symbol-24x24.jpg 24w, ' + domainURL + '/wp-content/uploads/2019/01/Interactive-Hand-Symbol.jpg 62w" sizes="(max-width: 50px) 100vw, 50px"></img>'
    const id = props.id;

    const LessonInfo = async () => {

        const lessonDetails = await getLessonByID(id);
        if (language != "FR") {
            setLessonsTitle(lessonDetails[0]?.title_en);
            setLessonsBody(lessonDetails[0]?.lessonText_en || "NONE");
            setLessonsQuiz(lessonDetails[0]?.quiz || "NONE");
            setLessonsVideo(lessonDetails[0]?.videoURL_en || "NONE");
            setAudioFile(lessonDetails[0]?.audioFile || "NONE");
            setViceoFramePoster("#t=2")
            setLessonAudioLabel("Lesson Audio")
            setInterStrucLabel("Interactive Structure")
        }
        console.log("VTT" + lessonDetails[0]?.vttFile);
        await setLessonVTT("https://www.smart-biology.com/" + lessonDetails[0]?.vttFile || null);
        if (language == "FR") {
            setLessonsTitle(lessonDetails[0]?.title_fr);
            setLessonsBody(lessonDetails[0]?.lessonText_fr || "NONE");
            setLessonsQuiz(lessonDetails[0]?.quiz || "NONE");
            setLessonsVideo(lessonDetails[0]?.videoURL_fr || "NONE");
            setAudioFile(lessonDetails[0]?.audioFile_fr || "NONE");
            setViceoFramePoster("#t=10")
            setLessonAudioLabel("Audio de la leçon")
            setInterStrucLabel("Structure interactive")
        }

        setInteractiveStructures(lessonDetails[0]?.interactiveStructure || "NONE");
        setInteractiveStructures2(lessonDetails[0]?.interactiveStructure2 || "NONE");
        setInteractiveStructures3(lessonDetails[0]?.interactiveStructure3 || "NONE");
        if (lessonDetails[0].videoPoster_en != null || lessonDetails[0].videoPoster_en != undefined) {
            var parsedURL = url.parse(lessonDetails[0].videoPoster_en);
            var updatedURL = domainURL + parsedURL.pathname;
            setLessonsVideoPoster(updatedURL);
        }

        // if (lessonDetails[0]?.interactiveStructureDesc_en != null || lessonDetails[0]?.interactiveStructureDesc_en != undefined) {
        //     const searchString = lessonDetails[0]?.interactiveStructureDesc_en;
        //     const searchRegex = /(<img .*?>)/;
        //     const updatedString = searchString.replace(searchRegex, '');
        //     setInteractiveStructuresDesc_EN(lessonDetails[0]?.interactiveStructureDesc_en || "NONE")
        //     setInteractiveStructuresDesc_EN(updatedString || "NONE")
        // } else {
        //     setInteractiveStructuresDesc_EN(lessonDetails[0]?.interactiveStructureDesc_en || "NONE")
        // }
        if (lessonDetails[0]?.interactiveStructureDesc_en != null) {
            const searchString = lessonDetails[0].interactiveStructureDesc_en;
            const searchRegex = /(<img .*?>)/; // Your existing regex to remove <img> tags
            const fullScreenRegex = /\[Click here for FULL SCREEN\]/g; // Regex to remove the full-screen text

            // Perform both replacements
            const updatedString = searchString
                .replace(searchRegex, '')
                .replace(fullScreenRegex, '');

            setInteractiveStructuresDesc_EN(updatedString);
        } else {
            setInteractiveStructuresDesc_EN("NONE");
        }
        if (lessonDetails[0]?.lessonFigures != null || lessonDetails[0]?.lessonFigures != undefined) {
            const figureList = () => {
                return (
                    <>
                        {lessonDetails[0]?.lessonFigures.length > 0 &&
                            <>
                                <div><h3 style={{ color: "#054bb4", 'font-size': "25px" }}>{figuresLabel}</h3></div>
                            </>
                        }
                        {Object.keys(lessonDetails[0]?.lessonFigures).map(key =>
                            <>
                                <LessonFigures figures={lessonDetails[0]?.lessonFigures[key]} /><br />
                            </>
                        )}
                    </>
                );
            };

            var figures001 = await figureList();
            setLessonFigures(figures001);
        }

        var lessonStatus = await getUserProgressByItemId("LESSON", id);

        if (lessonStatus == "Not Started") {
            updateUserProgress("LESSON", id, 'IN-PROGRESS');
        }
    }

    const moveToNextLesson = (next, max) => {
        props.eventChange(next, max, props.nextModule)
    }

    useEffect(async () => {
        try {

            await LessonInfo();
        } catch (err) {
            console.log(err);
        }

        const player = document.querySelector('[aria-haspopup="menu"]');
        if (player) {
            player.removeAttribute('aria-haspopup'); // Remove the attribute
        }

    }, [props.nextModuleId])

    const dialogRef = useRef(null);
    const firstFocusableRef = useRef(null);
    const lastFocusableRef = useRef(null);
    useEffect(() => {
        const dialog = dialogRef.current;
        const firstFocusableElement = firstFocusableRef.current;
        const lastFocusableElement = lastFocusableRef.current;

        const handleKeyDown = (e) => {
            // Handle Shift+Tab when on the first focusable element
            if (e.target === firstFocusableElement && e.key === 'Tab' && e.shiftKey) {
                e.preventDefault();
                lastFocusableElement.focus();
            }
            // Handle Tab when on the last focusable element
            else if (e.target === lastFocusableElement && e.key === 'Tab' && !e.shiftKey) {
                e.preventDefault();
                firstFocusableElement.focus();
            }
        };

        // Attach event listener to dialog
        if (dialog) {
            dialog.addEventListener('keydown', handleKeyDown);
        }

        // Clean up event listener on unmount
        return () => {
            if (dialog) {
                dialog.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []); // Empty dependency array ensures this runs only once when component mounts


    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    const [showSubtitles, setShowSubtitles] = useState(true); // State to track subtitle visibility
    const playerRef = useRef(null); // Ref to access the player instance

    const handleToggleSubtitles = () => {
        setShowSubtitles(!showSubtitles);
    };

    useEffect(() => {
        var elements = document.getElementsByClassName("video-react-button");

        for (var i = 0; i < elements.length; i++) {
            elements[i].removeAttribute("aria-live");
        }

        elements = document.getElementsByClassName("tab pane")

        for (var i = 0; i < elements.length; i++) {
            elements[i].setAttribute("role", "tabpanel");
            elements[i].setAttribute("aria-labeledby", id);
        }
    });

    return (
        <div role='tabpanel' id="mainContent" index="-1" aria-labelledby={lessonTitle} style={{ padding: "0px" }}>
            <span style={{ padding: "0px", 'text-align': "center" }}>
                <h3 >{lessonTitle}</h3>
            </span><br /><br />
            <Container style={{ 'padding-left': "0", 'padding-right': "0" }}>
                <Row>
                    <Col sm={12}>
                        {lessonVideo != "NONE" && //need to make into own component
                            <Container>
                                <Row>
                                    <Player
                                        playsInline
                                        src={lessonVideo}
                                        preload="metadata"
                                        onContextMenu={handleContextMenu}
                                        poster={lessonVideoPoster}
                                        ref={playerRef}
                                    >
                                        <track
                                            kind="subtitles"
                                            src={lessonVTT}
                                            srcLang="en"
                                            label="English"
                                            default
                                        />
                                       
                                        <BigPlayButton position="center" />
                                        <ControlBar>
                                            <ReplayControl seconds={10} order={1.1} />
                                            <ForwardControl seconds={30} order={1.2} />
                                            <CurrentTimeDisplay order={4.1} />
                                            <TimeDivider order={4.2} />
                                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                            <VolumeMenuButton enabled />
                                        </ControlBar>
                                    </Player>
                                    <button onClick={handleToggleSubtitles}>
                                        {showSubtitles ? 'Hide Descriptive Details' : 'Show Descriptive Details'}
                                    </button>
                                </Row>
                            </Container>
                        }
                        {audioFile != "NONE" && //need to make into own component
                            <>
                                {/* <ReactPlayer
                                    url={audioFile}
                                    height="30px"
                                    width="100%"
                                    controls="true"
                                    role="region"
                                    aria-hidden="false"
                                    aria-expanded="true"
                                   
                                /> */}
                                <CustomAudioPlayer audioFile={audioFile} lessonAudioLabel={lessonAudioLabel} />
                                {/* <div style={{ 'padding-left': "20px", 'padding-top': "5px" }}><h4><strong>

                                    {lessonAudioLabel}

                                </strong></h4></div> */}
                            </>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col><br />
                        <div align="center" >
                            <hr style={{ width: "25%", borderTop: "3px dashed #bbb" }} />
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        {lessonFigures != "NONE" &&
                            <>
                                {lessonFigures}
                            </>
                        }
                        {interactiveStructures != "NONE" &&
                            <>
                                <Container>
                                    <Row>
                                        <Col><br />
                                            <div align="center" >
                                                <hr style={{ width: "25%", borderTop: "3px dashed #bbb" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h2 style={{ fontSize: "18" }}>
                                                <span style={{ color: "#054bb4", 'font-size': "25px" }}><strong>{interStrucLabel}</strong></span></h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div dangerouslySetInnerHTML={{ __html: handURL }} /><ReactMarkdown rehypePlugins={[rehypeRaw]} >{interactiveStructuresDesc_EN}</ReactMarkdown>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div style={{ position: "relative" }}>
                                                <iframe title="Interactive Structure" loading="lazy" style={{ visibility: "visible", opacity: "1" }} src={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures} width="100%" height="500px">
                                                    <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/vendor.75c1a58f3a7314a88499.js"></script>
                                                    <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/main.75c1a58f3a7314a88499.js"></script>
                                                    <div class="whs-app" style={{ width: "inherit", height: "inherit", position: "relative" }}>
                                                        <canvas style={{ width: "100%", height: "500px!important" }}>
                                                        </canvas>
                                                    </div>
                                                </iframe><br />
                                                <div style={{ 'text-align': "right", paddingBottom: "20px" }}>
                                                    <a href={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures} target="_blank">Click here for full screen</a>
                                                </div>
                                            </div>
                                            {/* <div style={{
                                                border: '2px solid #ccc',
                                                borderRadius: '5px',
                                                padding: '20px',
                                                paddingBottom: '20px',
                                                paddingTop: "20px",
                                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                width: "75%",
                                                margin: "auto"
                                            }}> */}
                                                {/* <Row>
                                                    <Col xs={8}>
                                                        <p><strong>View this In the real workd with AR!</strong></p>
                                                        <p>
                                                            Want to see this in the real work? Scan the QR Code to the right and experience enhanced experience
                                                        </p>
                                                    </Col>
                                                    <Col>
                                                        <div style={{ textAlign: "center" }}>
                                                            <QRComponent />
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                            {/* </div> */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {interactiveStructures2 != "NONE" &&
                                                <>
                                                    <div style={{ position: "relative" }}>
                                                        <iframe title="Interactive Structure" loading="lazy" style={{ visibility: "visible", opacity: "1" }} src={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures2} width="100%" height="500px">
                                                            <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/vendor.75c1a58f3a7314a88499.js"></script>
                                                            <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/main.75c1a58f3a7314a88499.js"></script>
                                                            <div class="whs-app" style={{ width: "inherit", height: "inherit", position: "relative" }}>
                                                                <canvas style={{ width: "100%", height: "500px!important" }}></canvas></div>
                                                        </iframe><br />
                                                        <div style={{ 'text-align': "right" }}>
                                                            <a href={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures2} target="_blank">Click here for full screen</a>
                                                        </div>
                                                    </div><br /><br />
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                    {interactiveStructures3 != "NONE" &&
                                        <Row>
                                            <Col>
                                                <>
                                                    <div style={{ position: "relative" }}>
                                                        <iframe title="Interactive Structure" loading="lazy" style={{ visibility: "visible", opacity: "1" }} src={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures3} width="100%" height="500px">
                                                            <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/vendor.75c1a58f3a7314a88499.js"></script>
                                                            <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/main.75c1a58f3a7314a88499.js"></script>
                                                            <div class="whs-app" style={{ width: "inherit", height: "inherit", position: "relative" }}>
                                                                <canvas style={{ width: "100%", height: "500px!important" }}></canvas></div>
                                                        </iframe><br />
                                                        <div style={{ 'text-align': "right" }}>
                                                            <a href={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures3} target="_blank">Click here for full screen</a>
                                                        </div>
                                                    </div><br /><br />
                                                </>
                                            </Col>
                                        </Row>
                                    }
                                </Container>
                                <div>
                                    {/* <QRComponent lessonName={lessonTitle} lessonId={id} /> */}
                                </div>
                            </>
                        }
                    </Col>
                </Row>

                {lessonBody != "NONE" &&
                    <>
                        <Row>
                            <Col>
                                <div align="center" style={{ paddingTop: "20px" }} >
                                    <hr style={{ width: "25%", borderTop: "3px dashed #bbb" }} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <h2 style={{ fontSize: "18" }}>
                                <span style={{ color: "#054bb4", 'font-size': "25px" }}><strong>{lessonTextLabel}</strong></span>
                            </h2>
                            <Col>
                                <ReactMarkdown rehypePlugins={[rehypeRaw]} >{lessonBody}</ReactMarkdown>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
            <Container align="right" style={{ 'margin-top': "100px" }}>
       
                            <UpdateButtonComponent nextModuleInd={props.nextModuleId} type="LESSON" id={id} status="COMPLETE" eventFunction={moveToNextLesson} nextLesson={props.nextLesson} maxKey={props.maxKey} />
                    
                            {lessonQuiz != "NONE" &&
                                <QuizComponent moduleId={moduleId} lessonId={id} id={lessonQuiz?.id} />
                            }
   
            </Container>
        </div>
    );
}

export default LessonContentComponent;