
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


export default function OrderModelComponentV2(props) {

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <h1 style={{'font-size': "24px", margin: "0"}}>Order Details</h1>
          <p style={{'font-size': "16px"}}>{props.orderList.id}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Container>
            <Row>
              <Col>
                <h2 style={{'font-size': "20px"}}>Product Details</h2>
                <div>{props.orderList.product[0].name}</div>
              </Col>
            </Row>

            <Row>
              <Col>
                <hr />
                <h2 style={{'font-size': "20px"}}>Payment Details</h2>
                <div className="d-flex justify-content-between">
                  <div>Subtotal:</div>
                  <div>${props.orderList.orderTotal.subTotal.toFixed(2)}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>Total Discounts:</div>
                  <div>${props.orderList.orderTotal.totalDiscounts.toFixed(2)}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>Sales Tax: </div>
                  <div>${props.orderList.orderTotal.taxes.toFixed(2)}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>Total:</div>
                  <div>${props.orderList.orderTotal.Total.toFixed(2)} ({props.orderList.product[0].currency})</div>
                </div>
                <div>Payment Method: {props.orderList.orderTotal.paymentMethod}</div>
              </Col>
            </Row>

            <Row>
              <Col>
                <hr />
                <h2 style={{'font-size': "20px"}}>Customer Details</h2>
                <div>Name: {props.orderList.customer.name}</div>
                <div>State/Province: {props.orderList.customer.province}</div>
                <div>Postal/Zip Code: {props.orderList.customer.postCode}</div>
                <div>Country: {props.orderList.customer.country}</div>

                {/* ... Repeat for other customer details */}
              </Col>
            </Row>
          </Container>
        </p>
      </Modal.Body>
    </>
  );
}