import React, { useState, useEffect, useContext } from "react";
import FormSelect from 'react-bootstrap/FormSelect'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert'

function ArManagementLessonUpdateComponent(props) {
  const [selectedDiagrams, setSelectedDiagrams] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [selectedMemberships, setSelectedMemberships] = useState([]);
  const [lessonSearchTerm, setLessonSearchTerm] = useState('');
  const [membershipSearchTerm, setMembershipSearchTerm] = useState('');
  const [resultBanner, setResultBanner] = useState('');

  const [TESTPAYLOAD, setPayload] = useState("");


  useEffect(() => {
  //to be used later, if needed
  }, []);

  const handleDiagramChange = (event) => {
    setSelectedDiagrams(Array.from(event.target.selectedOptions, option => option.value));
  };

  const handleLessonChange = (event) => {
    setSelectedLessons(Array.from(event.target.selectedOptions, option => option.value));
  };

  const handleMembershipChange = (event) => {
    setSelectedMemberships(Array.from(event.target.selectedOptions, option => option.value));
  };

  const handleAssign = () => {
    // Send the selectedDiagrams and selectedLessons to your backend for assignment
    console.log('Assigning diagrams:', selectedDiagrams, 'to lessons:', selectedLessons);
    setPayload({
      structure: selectedDiagrams[0],
      lessonsList: selectedLessons,
      membershipLst: selectedMemberships
    })

    var succes = false
    var userMsg = ""
    var alertType = ""

    if (succes) {
      alertType = "success"
      userMsg = "You good!"
    }

    if (!succes) {
      alertType = "danger"
      userMsg = "You bad!"

    }

    //If Successfull
    setResultBanner(() => {
      return (
        <Alert key={alertType} variant={alertType}>
          {userMsg}
        </Alert>
      )
    })

  };

  return (
    <div>
      <div style={{ display: 'flex', padding: "10px" }}>
        {/* Diagram Selection */}
        <div style={{ width: '40%', height: "200px", padding: "10px" }}>
          <h6>Step 1: <br />Select an AR Structure</h6>< hr />
          <FormSelect
            value={selectedDiagrams}
            onChange={handleDiagramChange}
          // Adjust height to accommodate heading 
          >
            {props.diagrams.map(diagram => (
              <option style={{ fontSize: "14px" }} key={diagram.id} value={diagram.id}>{diagram.name}</option>
            ))}
          </FormSelect>
        </div>

        {/* Lesson Selection */}
        <div style={{ width: '50%', fontSize: "14px", height: "275px", padding: "10px" }}>
          <h6>Step 2: <br />Select the Lessons</h6>< hr />

          <div style={{ textAlign: 'left' }}>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Search lessons..."
              value={lessonSearchTerm}
              onChange={(e) => setLessonSearchTerm(e.target.value)}
            />
          </div>
          <Form.Text id="passwordHelpBlock" muted>
            Hold ctrl to select multiple lessons
          </Form.Text>

          <FormSelect
            multiple
            value={selectedLessons}
            onChange={handleLessonChange}
            style={{ height: "150px" }}

          >
            {props.lessons
              .filter(lesson =>
                lesson.lesson.toLowerCase().includes(lessonSearchTerm.toLowerCase())
              )
              .map(lesson => (
                <option key={lesson.id} style={{ fontSize: "14px" }} value={lesson.id}>
                  {lesson.lesson}
                </option>
              ))
            }
          </FormSelect>
        </div>

        {/* Membership Selection */}
        <div style={{ width: '50%', fontSize: "14px", height: "275px", padding: "10px" }}>
          <h6>Step 3: <br />Select the Memberships</h6>< hr />

          <div style={{ textAlign: 'left' }}>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Search Memberships..."
              value={membershipSearchTerm}
              onChange={(e) => setMembershipSearchTerm(e.target.value)}

            />
          </div>
          <Form.Text id="passwordHelpBlock" muted>
            Hold ctrl to select multiple memberships
          </Form.Text>

          <FormSelect
            multiple
            value={selectedMemberships}
            onChange={handleMembershipChange}
            style={{ height: "150px" }}
          >
            {props.memberships
              .filter(membership =>
                membership.membershipKey.toLowerCase().includes(membershipSearchTerm.toLowerCase())
              )
              .map(membership => (
                <option key={membership.membershipKey} style={{ fontSize: "14px" }} value={membership.membershipKey}>
                  {membership.membershipKey}
                </option>
              ))
            }
          </FormSelect>
        </div>
      </div>

      {/* Assign Button */}
      <div style={{ textAlign: 'right', paddingRight: "20px", paddingBottom: "20px" }}>
        <Button onClick={handleAssign} style={{ marginTop: '20px' }}>
          Assign
        </Button>
      </div>

{/* {JSON.stringify(TESTPAYLOAD)} */}

      {resultBanner}
    </div>
  );
}

export default ArManagementLessonUpdateComponent;
